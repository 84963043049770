<template>
  <footer class="footer-footer">
    <div class="footer-container">
      <div class="footer-container1">
        <span class="footer-text">CrimeLogic</span>
        <span>Copyright © 2022</span>
      </div>
    </div>
    <img
      alt="image"
      src="/playground_assets/waves-white.svg"
      class="footer-image"
    />
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {},
}
</script>

<style scoped>
.footer-footer {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(310deg,#141727,#3a416f);
}
.footer-container {
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  z-index: 1;
  max-width: 1320px;
  justify-content: space-between;
}
.footer-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-text {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-image {
  top: 0px;
  left: auto;
  right: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  position: absolute;
  object-fit: cover;
}
@media(max-width: 1200px) {
  .footer-text {
    font-weight: 700;
  }
}
@media(max-width: 991px) {
  .footer-container {
    padding: var(--dl-space-space-doubleunit);
  }
}
@media(max-width: 767px) {
  .footer-container {
    flex-direction: column;
  }
  .footer-container1 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
}
</style>
