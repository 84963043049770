<template>
  <div class="coming-soon-container">
    <app-header></app-header>
    <div class="coming-soon-container1">
      <div class="coming-soon-main">
        <div class="coming-soon-container2">
          <h1 class="coming-soon-text headingOne">You Work With</h1>
          <h1 class="coming-soon-text1">Soft Design System</h1>
          <p class="coming-soon-text2 lead">
            <span class="coming-soon-text3">
              The time is now for it be okay to be great. Subscribe now and get
              notified when it&apos;s launched!
            </span>
          </p>
          <div class="coming-soon-container3">
            <input
              type="text"
              placeholder="Email here"
              class="coming-soon-textinput small input"
            />
            <secondary-button button="Subscribe"></secondary-button>
          </div>
        </div>
        <div class="coming-soon-grid">
          <img
            alt="image"
            src="/playground_assets/iphone-3-1000w.png"
            class="coming-soon-image"
          />
          <img
            alt="image"
            src="/playground_assets/iphone-2-1000w.png"
            class="coming-soon-image01"
          />
          <img
            alt="image"
            src="/playground_assets/iphone-4-1000w.png"
            class="coming-soon-image02"
          />
          <img
            alt="image"
            src="/playground_assets/iphone-1-1000w.png"
            class="coming-soon-image03"
          />
          <img
            alt="image"
            src="/playground_assets/iphone-2-1000w.png"
            class="coming-soon-image04"
          />
          <img
            alt="image"
            src="/playground_assets/iphone-4-1000w.png"
            class="coming-soon-image05"
          />
          <img
            alt="image"
            src="/playground_assets/iphone-1-1000w.png"
            class="coming-soon-image06"
          />
          <img
            alt="image"
            src="/playground_assets/iphone-3-1000w.png"
            class="coming-soon-image07"
          />
          <img
            alt="image"
            src="/playground_assets/iphone-2-1000w.png"
            class="coming-soon-image08"
          />
          <img
            alt="image"
            src="/playground_assets/iphone-4-1000w.png"
            class="coming-soon-image09"
          />
          <img
            alt="image"
            src="/playground_assets/iphone-1-1000w.png"
            class="coming-soon-image10"
          />
          <img
            alt="image"
            src="/playground_assets/iphone-3-1000w.png"
            class="coming-soon-image11"
          />
          <img
            alt="image"
            src="/playground_assets/iphone-4-1000w.png"
            class="coming-soon-image12"
          />
          <img
            alt="image"
            src="/playground_assets/iphone-1-1000w.png"
            class="coming-soon-image13"
          />
          <img
            alt="image"
            src="/playground_assets/iphone-3-1000w.png"
            class="coming-soon-image14"
          />
          <img
            alt="image"
            src="/playground_assets/iphone-2-1000w.png"
            class="coming-soon-image15"
          />
        </div>
      </div>
    </div>
    <footer-gray></footer-gray>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import SecondaryButton from '../components/secondary-button'
import FooterGray from '../components/footer-gray'

export default {
  name: 'ComingSoon',
  components: {
    AppHeader,
    SecondaryButton,
    FooterGray,
  },
  metaInfo: {
    title: 'ComingSoon - CrimeLogic - Game Studio',
    meta: [
      {
        property: 'og:title',
        content: 'ComingSoon - CrimeLogic - Game Studio',
      },
    ],
  },
}
</script>

<style scoped>
.coming-soon-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-900);
}
.coming-soon-container1 {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.coming-soon-main {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 0;
  position: relative;
  max-width: 1320px;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  justify-content: flex-end;
}
.coming-soon-container2 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  z-index: 2;
  align-self: flex-end;
  margin-top: var(--dl-space-space-sixunits);
  align-items: flex-start;
  margin-left: var(--dl-space-space-tripleunit);
  padding-top: var(--dl-space-space-tripleunit);
  margin-right: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tripleunit);
}
.coming-soon-text {
  font-size: 3rem;
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(310deg,#7928ca,#ff0080);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.coming-soon-text1 {
  color: var(--dl-color-secondary-700);
  font-size: 3rem;
  margin-bottom: var(--dl-space-space-halfunit);
}
.coming-soon-text2 {
  margin-bottom: var(--dl-space-space-unit);
}
.coming-soon-text3 {
  color: rgb(103, 116, 142);
  display: inline;
  font-weight: 400;
  white-space: normal;
}
.coming-soon-container3 {
  flex: 0 0 auto;
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.coming-soon-textinput {
  width: 100%;
  transition: 0.3s;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-secondary-100);
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-triplequarterunit);
  border-radius: var(--dl-radius-radius-radius5);
  padding-right: var(--dl-space-space-triplequarterunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.coming-soon-textinput:focus {
  outline: 0;
  box-shadow: 0 0 0 2px #e9aede;
  border-color: var(--dl-color-pink-200);
}
.coming-soon-grid {
  top: -20%;
  left: -35%;
  width: 70%;
  display: grid;
  grid-gap: var(--dl-space-space-doubleunit);
  position: absolute;
  transform: rotate(20deg);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.coming-soon-image {
  width: 100%;
}
.coming-soon-image01 {
  width: 100%;
  margin-top: -200px;
}
.coming-soon-image02 {
  width: 100%;
  margin-top: -50px;
}
.coming-soon-image03 {
  width: 100%;
}
.coming-soon-image04 {
  width: 100%;
}
.coming-soon-image05 {
  width: 100%;
  margin-top: -200px;
}
.coming-soon-image06 {
  width: 100%;
  margin-top: -50px;
}
.coming-soon-image07 {
  width: 100%;
}
.coming-soon-image08 {
  width: 100%;
}
.coming-soon-image09 {
  width: 100%;
  margin-top: -200px;
}
.coming-soon-image10 {
  width: 100%;
  margin-top: -50px;
}
.coming-soon-image11 {
  width: 100%;
}
.coming-soon-image12 {
  width: 100%;
}
.coming-soon-image13 {
  width: 100%;
  margin-top: -200px;
}
.coming-soon-image14 {
  width: 100%;
}
.coming-soon-image15 {
  width: 100%;
}
@media(max-width: 991px) {
  .coming-soon-container1 {
    background-size: auto;
  }
  .coming-soon-main {
    justify-content: flex-start;
  }
  .coming-soon-container2 {
    width: 70%;
    align-self: flex-start;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-tripleunit);
    margin-bottom: 0px;
    padding-right: var(--dl-space-space-tripleunit);
  }
  .coming-soon-grid {
    top: 40%;
    left: auto;
    right: -6rem;
    bottom: -6rem;
  }
  .coming-soon-image {
    width: 120px;
  }
  .coming-soon-image01 {
    width: 120px;
  }
  .coming-soon-image02 {
    width: 120px;
  }
  .coming-soon-image03 {
    width: 120px;
  }
  .coming-soon-image04 {
    width: 120px;
  }
  .coming-soon-image05 {
    width: 120px;
  }
  .coming-soon-image06 {
    width: 120px;
  }
  .coming-soon-image07 {
    width: 120px;
  }
  .coming-soon-image08 {
    width: 120px;
  }
  .coming-soon-image09 {
    width: 120px;
  }
  .coming-soon-image10 {
    width: 120px;
  }
  .coming-soon-image11 {
    width: 120px;
  }
  .coming-soon-image12 {
    width: 120px;
  }
  .coming-soon-image13 {
    width: 120px;
  }
  .coming-soon-image14 {
    width: 120px;
    margin-top: -50px;
  }
  .coming-soon-image15 {
    width: 120px;
  }
}
@media(max-width: 767px) {
  .coming-soon-container2 {
    width: 100%;
  }
  .coming-soon-grid {
    right: 0;
  }
  .coming-soon-image {
    width: 100px;
  }
  .coming-soon-image01 {
    width: 100px;
  }
  .coming-soon-image02 {
    width: 100px;
  }
  .coming-soon-image03 {
    width: 100px;
  }
  .coming-soon-image04 {
    width: 100px;
  }
  .coming-soon-image05 {
    width: 100px;
  }
  .coming-soon-image06 {
    width: 100px;
  }
  .coming-soon-image07 {
    width: 100px;
  }
  .coming-soon-image08 {
    width: 100px;
  }
  .coming-soon-image09 {
    width: 100px;
  }
  .coming-soon-image10 {
    width: 100px;
  }
  .coming-soon-image11 {
    width: 100px;
  }
  .coming-soon-image12 {
    width: 100px;
  }
  .coming-soon-image13 {
    width: 100px;
  }
  .coming-soon-image14 {
    width: 100px;
  }
  .coming-soon-image15 {
    width: 100px;
  }
}
@media(max-width: 479px) {
  .coming-soon-container3 {
    flex-direction: column;
  }
  .coming-soon-textinput {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
}
</style>
